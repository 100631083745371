export default {
  computed: {
    cartEmpty() {
      return this.order.total_quantity == undefined || this.order.total_quantity < 1
    },
    cartTotalQuantity() {
      return this.order.total_quantity
    },
    checkoutDisabled() {
      if (this.order.line_items == undefined) return true

      for (let line_item of this.order.line_items) {
        if (!line_item.fulfils_restrictions) {
          return true
        }
      }
      return false
    }
  }
}
