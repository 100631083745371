import Utils from '../utils'
import NiceI18nTranslationsEn from "./NiceI18nTranslationsEn"
import NiceI18nTranslationsGr from "./NiceI18nTranslationsGr"

export default class NiceI18nTranslations {
  constructor() {
    window.niceI18nTranslations = {
      el: new NiceI18nTranslationsGr,
      en: new NiceI18nTranslationsEn
    }

    if (window.projectI18nTranslations && !Utils.checkForEmptyObject(window.projectI18nTranslations)) {
      window.niceI18nTranslations = Utils.deepMerge(window.niceI18nTranslations, window.projectI18nTranslations)
    }
  }
}
