// Load translations
import NiceI18nTranslations from '../lib/i18_translations/NiceI18nTranslations'
new NiceI18nTranslations()

export default class NiceI18n {
  static t(el, options) {
    if (typeof I18n == "undefined") {
      return this.get(el)
    }
    else {
      I18n.locale = window.locale
      return I18n.t(el, options)
    }
  }

  static get(key) {
    if (window.niceI18nTranslations == undefined) return ""
    if (Object.keys(window.niceI18nTranslations[window.locale]).length == 0) return ""

    let str = window.niceI18nTranslations[window.locale]

    key.split(".").forEach(element => {
      if (str !== undefined) str = str[element]
    })

    return str || ""
  }
}
